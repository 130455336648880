import {navigate} from "gatsby";
import React from "react";
import {useIsMobile} from "../utils";

const Contact = () => {
    const { isMobile } = useIsMobile();
    return <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(/index-pic4.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: 800,
            color: 'white',
            fontSize: 30,
            padding: isMobile ? '1rem' : 0,
        }}
    >
        多描述一些需求，讓我們可以幫助你。
        <div style={{height: 48}}/>
        <div
            style={{
                cursor: 'pointer',
                padding: '16px 12px',
                textAlign: 'center',
                border: '1px solid',
                width: 200,
                color: 'white',
                borderColor: 'white',
                borderRadius: 8,
                fontSize: 18,
            }}
            onClick={() => {
                window.open('https://m.me/cooperationcoffee', '_self' );
            }}
        >
            聯絡我們
        </div>
    </div>;
}

export  default  Contact;