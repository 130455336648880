import React, {useEffect} from "react";
import {useIsMobile} from "../utils";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";

const Hero = ({children, imageUrl}) => {
    const {isMobile} = useIsMobile();
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: {
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
        },
        exit: {
            opacity: 0,
        }
    };
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const motionProps = {
        animate: controls,
        initial: 'hidden',
        exit: 'exit',
        variants,
        ref,
    }

    return (
        <motion.div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'EB Garamond',
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '70vh',
                color: 'white',
                fontSize: 64,
                textAlign: 'center',
                padding: isMobile ? '1rem' : 0,
            }}
            {...motionProps}
        >
            {children}
        </motion.div>
    );
}
export default Hero;
