import React, { useEffect} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {navigate, graphql} from "gatsby";
import {useIsMobile} from "../utils";
import Story from "../components/story";
import Contact from "../components/contact";
import Hero from "../components/hero";
import {Product} from "../models/product";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

const MonthlyCoffee = ({ english, name, content, imageUrl, onClick }) => <div
  style={{ 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  }}
>
  <img
    src={imageUrl}
    style={{ maxWidth: 320, cursor: 'pointer' }}
    onClick={onClick}
  />
  <div style={{ height: 24 }}></div>
  <div>{english}</div>
  <div style={{ height: 4 }}></div>
  <div style={{ fontSize: 24 }}>{name}</div>
  <div style={{ height: 16 }}></div>
  <div
    style={{ maxWidth: 300 }}
  >
    <ReactMarkdown 
      rehypePlugins={[rehypeRaw]}
      components={{
        p: (props) => <div style={{ margin: '1.625px 0px', textAlign: 'justify' }} {...props} />
      }}
      children={content} />
  </div>
</div>

const BlogIndex = ({
                       data,
                       pageContext: {nextPagePath, previousPagePath},
                   }) => {
    const { isMobile } = useIsMobile();
    const { shopify } = data;
    const monthly = shopify.monthly.edges.map(Product.fromShopify).slice(0,3);
    const news = shopify.news.edges.map(Product.fromShopify);
    return (
        <div>
            <Seo title="合作咖啡"/>
            <div
                style={{
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    width: `100%`,
                }}
            >
                <Hero imageUrl={'/index-pic1.jpg'}>
                  <div style={{ 
                    display: `flex`,
                      flexDirection: `column`,
                      alignItems: `center`,
                  }}>
                    <div
                        id="game-btn"
                        onClick={() => {
                            navigate('/game');
                        }}
                    >
                        <div style={{ marginTop: -4 }}>
                          找到適合你的咖啡豆
                        </div>
                    </div>
                  </div>

                </Hero>
                <div
                    style={{
                        padding: '4rem 4rem',
                        maxWidth: 657,
                        textAlign: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    Cooperation Coffee
                    <br/>
                    <div style={{fontSize: 40}}>
                        合作咖啡
                    </div>
                    <div style={{textAlign: 'justify', padding: isMobile ? '0 16px': '64px 0'}}>
                        Cooperation Coffee
                        是一家位於台灣的咖啡公司。合作咖啡成立於2017年，我們從網路販賣咖啡豆開始。我們希望為客人推薦最符合個人口味的咖啡豆，您可以點選下面按鈕來輸入偏好，有任何問題都歡迎聯繫我們！
                    </div>
                </div>
            </div>
            <br/>
            <div
              style={{
                margin: '0px auto',
                maxWidth: 1300
              }}
            >
              
              <div style={{ fontSize: 32, margin: '0px auto', width: '100%', textAlign: 'center' }}>
                每月推薦
              </div>
              <div style={{
                padding: '64px 64px 128px 64px',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: 24,
                justifyContent: 'space-around',
              }}>
                {
                  monthly.map((p) => ({
                    name: p.name,
                    english: p.engName,
                    content: p.shortDescription,
                    imageUrl: p.coverUrl,
                    onClick: () => {
                        navigate(`/products/${p.id}`);
                    }
                  })).map((c) => <MonthlyCoffee {...c}
                    />)
                }
              </div>
              {
                ['首頁公告-1','首頁公告-2','首頁公告-3']
                  .map((v) => news.find(({ tags }) => tags.includes(v)))
                  .filter((v) => v)
                  .map(({ name, coverUrl, description }, i) => 
                    <Story
                        direction={i % 2 === 1 ? 'left' : 'right'}
                        imageUrl={coverUrl}
                        header={name}
                        content={description}
                        />
                  )
              }
              <div style={{ height: 64}} />
            </div>
            <Contact />

        </div>
    )
}

export const query = graphql`{ 
  shopify{
    monthly: products(first: 3, query: "tag:每月推薦") {
     edges {
       node {
         handle
         title
         featuredImage {
           url
         }
         metafields(first: 30) {
           edges {
             node {
               value
               key
               reference {
                 ...on SHOPIFY_MediaImage{
                   image {
                     url
                   }
                 }
               }
             }
           }
         }
         images(first: 10) {
           edges {
             node {
               url(transform: { 
                 crop: CENTER
                 maxWidth: 500,
                 maxHeight: 500})
               id
               thumbnailUrl: url(transform: { 
                 crop: CENTER
                 maxWidth: 360,
                 maxHeight: 360})
             }
           }
         }
       }
     }
    }
    news: products(first: 30, query: "product_type:非商品") {
      edges {
        node {
          title
          featuredImage {
            url
          }
          tags
          description
          images(first: 10) {
            edges {
              node {
                url(transform: { 
                  crop: CENTER
                  maxWidth: 500,
                  maxHeight: 500})
                id
                thumbnailUrl: url(transform: { 
                  crop: CENTER
                  maxWidth: 360,
                  maxHeight: 360})
              }
            }
          }
        }
      }
    }
  }
}`;

export default BlogIndex;
